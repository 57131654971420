@import './theme.variables.scss';

// Reset gutters.
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
  width: 100%;

  // font-family: $font-family-text;
}

.text-centered {
  text-align: center;
}

.link-dse {
  box-shadow: 0 1px 0 0 currentColor;
}




@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  font-display: swap;
  src: url('./Nunito-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

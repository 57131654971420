@import 'style/theme.variables';

@mixin impulseSpinner(
  $color: #3498db,
  $backgroundColor: $light-gray,
  $size: 1rem,
  $animationDuration: 1.25s,
) {
  $animationName: unique-id();

  width: $size * 5;
  height: $size;

  .ball {
    position: absolute;
    top: 0;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $backgroundColor;
    animation: $animationName $animationDuration linear infinite;
  }

  @for $i from 1 through 3 {
    .ball#{$i} {
      left: ($i - 1) * $size * 2;
      animation-delay: $animationDuration * ($i - 1) / 10;
    }
  }

  @keyframes #{$animationName} {
    0% {
      transform: scale(1);
      animation-timing-function: cubic-bezier(1, 0, 0.7, 1);
    }

    40% {
      background: $color;
      transform: scale(1.5);
      animation-timing-function: cubic-bezier(0.3, 0, 0, 1);
    }

    72.5% {
      background: $backgroundColor;
      transform: scale(1);
      animation-timing-function: linear;
    }

    100% {
      transform: scale(1);
    }
  }
}

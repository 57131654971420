@import './fonts/Montserrat/style.scss';
@import './fonts/Nunito/style.scss';

// Colors.
$primary-color: #e74c3c; // Primary color for all components.
$link-color: #3498db; // Link color.
$blue-color: #30AABC;
$back-color: #B7E2E8;
$link-color: #00468E;
$light-gray: #ecf0f1; // Light gray.

// Fonts.
$font-family-title: Montserrat, sans;
$font-family-text: Nunito, sans;



@import './impulse-spinner.mixin.scss';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.default {
    @include impulseSpinner();
  }
}
